#title{
    font-weight: 600;
    text-shadow: 1px 1px 1px #2b2d42;
    .span{
        color: #d90429;
    }
    @media screen and (max-width:500px) {
       h1{
        font-size: 17px;
       }
       }
       @media screen and (max-width:400px) {
        h1{
         font-size: 13px;
        }
        }
        @media screen and (max-width:300px) {
            h1{
             font-size: 11px;
            }
            }
  }
  .mail-btn{
        background-color: #d90429;
        border-radius: 20px;
        cursor: pointer;
        transition: 0.3s;
        box-shadow: 2px 2px 2px;
        border: none;
        img{
            width: 30px  !important;
            height: 30px !important ;
            @media screen and (max-width:500px) {
                height: 20px !important ;
                width: 20px  !important;
                border-radius: 10px;
            }
        }
  }
  .btn-wth{
    background-color: #99d98c;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 2px 2px 2px;
    transition: 0.3s;
    img{
        width: 30px;
        height: 30px;
        @media screen and (max-width:500px) {
            height: 20px !important ;
            width: 20px  !important;
        }
    }
    border: none;
  }
  .btn-wth:hover{
    background-color: #ffffff;
  }
  .mail-btn:hover{
    background-color: #9b404e;
  }
  .navbar{
    z-index: 1000;
    background-color: #bc4749;
    .link{
        text-align: center;
        color: #ffffff;
    }
    .link:hover{
        text-decoration: 2px underline #2b2d42;
    }
  }
  body, html {
    width: 100%;
    overflow-x: hidden; 
  }
  #nav-dropdown.dropdown-toggle.nav-link{
    text-decoration: none;
    text-align: center;
    color: #ffffff;
    .link{
        color: #2b2d42;
        text-decoration: none;
    }
  }
  a.link.dropdown-item{
    color: #2b2d42;
    text-decoration: none;       
  }
  
  .dropdown-item:active{
    background-color: #f7f7f7;
    color: #2b2d42;
  }
  .alert-card{
    z-index: 2000;
    border-radius: 5px;
    position: absolute;
    top: 25vh;
    padding: 10px;
    left: 250px;
    background-color: #ffffff;
    width: 50%;
    font-weight: 600;
    @media screen and (max-width:1200px) {
        left: 100px;
    }
    @media screen and (max-width:900px) {
        left: 10px;
    }
    @media screen and (max-width:900px) {
        width: 90%;
    }
    .btn{
      display: flex;
      align-items: center;
      button{
          background-color: #bc4749;
          padding: 5px;
          margin: 3px;
          font-size: 18px;
          width: 50%;
      }
    }
  }

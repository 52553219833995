.course-card{
    margin: 10px;
    padding: 8px;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-shrink: 1;
    flex-grow: 1;
    border-radius: 10px;
    flex-wrap: wrap;
    flex-basis: 200;
    transition: 0.3s;
    box-shadow: 1px 1px 1px #f0f0f0;
    background-color: #f8f8f8;
    span{
        color: #fff;
    }
    h1{
        margin: 10px;
        padding: 10px;
    }
}
.course-card:hover{
    transform:translateY(-5px);
    box-shadow: 1px 1px 1px #cccccc;
    background-color: #fff9f9;
}
.container{
    width: 100%;
    img{
        width: 100%;
        border-radius: 5px;
    }
}
#course-btn{
    margin: 5px;
    font-size: 15px;
    border: none;
    color: #8b121c;
    background:none;
    transition: 0.6s;
    font-weight: 600;
    @media screen and (max-width:1000px) {
        font-size: 15px;
    }
}
.card-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}
#course-btn:hover{
    box-shadow: 1px 1px 1px #cccccc;
}
.btn-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btn-col{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
h1{
    font-weight: 600;
}
.search-cqrd{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.search-result{
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    ul{
        margin: 0px;
        border-radius: 10px;
        padding: 10px;
        background-color: rgba(66, 66, 66, 0.897);
        width: 70%;
        @media screen and (max-width:600px) {
            width: 96% !important;
        }
        z-index: 2000 !important;
        li{
            font-size: 18px;
            margin: 2px;
            color: rgb(255, 255, 255);
            list-style: none;
            padding: 10px;
            transition: 0.3s;
            cursor: pointer;
            border-radius: 5px;
        }
        li:hover{
            background-color: white;
            color: black;
        }
    }
}

@media screen and (min-width:601px){
    .form{
       margin:20px;
       margin-left: 20%;
       width:60%;

    }
   }
   @media screen and (min-width:901px){
    .form{
       margin:20px;
       margin-left: 25%;
       width:50%;

    }
   }
   @media screen and (min-width:1000px){
    .form{
       margin:20px;
       margin-left: 30%;
       width:40%;

    }
   }
   @media screen and (max-width:600px){
    .form{
       margin-left: 1%;
       width:90%;
       margin:20px
    }
   }
.form{
    border: 1px;
    padding: 10px;
    border-radius: 10px;
    input{
        background-color: #ffff;
        border: 1px solid #eee;
        padding: 15px;
    }

}
#submit{
    width: 100%;
    border: none;
    transition: 0.3s;
    padding: 10px;
    background-color: #8b121c;
}
#submit:hover{
   background-color: #790a13;
}
.form-container{
   box-shadow: 3px 3px 3px #f5f5f5;
   margin: 20px;
   width: 97%;
   padding: 10px;
   background-color: #ebebeb;
   .form{
      padding: 20px;
      background-color: #ffff;
      box-shadow: 3px 3px 3px #f5f5f5;

   }
   @media screen and (max-width:1067px) {
      width: 100%;
      margin: 0;
   }
}